import * as React from 'react';
import { getCookie, deleteCookie } from '../lib/utils';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';

function LaunchplanPage() {
  const [mounted, setMounted] = React.useState(false);
  const [isKlaviyoAccepted, setIsKlaviyoAccepted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
    const checkKlaviyoCookie = () => {
      const klaviyoCookie = getCookie('gatsby-gdpr-klaviyo');
      setIsKlaviyoAccepted(klaviyoCookie === 'true');
    };

    checkKlaviyoCookie();
    const intervalId = setInterval(checkKlaviyoCookie, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function openCookieSettings() {
    Object.keys(window.gatsbyPluginGDPRCookiesOptions).forEach((key) => {
      if (key !== 'environemnts' && key !== 'plugins' && key) {
        localStorage.removeItem(window.gatsbyPluginGDPRCookiesOptions[key].cookieName);
        if (
          getCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName) !== ''
        ) {
          localStorage.setItem(
            window.gatsbyPluginGDPRCookiesOptions[key].cookieName,
            getCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName),
          );
        }
        deleteCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName);
      }
    });

    window.location.reload();
  }

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'LaunchplanPage', fullWidth: true });
        return (
          <>
            <Seo title="kostenloser Shopify-Launchplan" />
            <div className="px-4 lg:px-16 pt-20 lg:pt-36">
              {mounted && !isKlaviyoAccepted ? (
                <div className="min-h-[50px]">
                  <button
                    type="button"
                    className="underline cursor-pointer"
                    onClick={openCookieSettings}
                  >
                    Bitte stimmen Sie erst den Cookies zu,
                  </button>
                  <p>
                    {' '}
                    um das kostenlose Shopify-Launchplan-Formular zu laden.
                  </p>
                </div>
              ) : (
                <div
                  className="klaviyo-form-RYTZnv cursor-pointer"
                  data-klaviyo-accepted={isKlaviyoAccepted}
                />
              )}
            </div>
            <div className="my-24 lg:my-60" />
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default LaunchplanPage;
